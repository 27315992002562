html,
body,
.container {
  height: 85vh;
  width: 100vw;
}
.album {
  flex-wrap: wrap;
  display: -webkit-flexbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
}
.card {
  /* Add shadows to create the "card" effect */
  border-radius: 1vh;
  margin-top: 2vh;
  margin-left: 1vh;
  margin-right: 1vh;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
